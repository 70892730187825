import React from 'react';
import { Modal } from 'react-bootstrap';
import { MdClose, MdError } from "react-icons/md";


const ErrorPopup = ({ show, message, onClose }) => {
    return (
        <Modal dialogClassName='error-dialog' contentClassName='error-dialog-content' show={show} onBackdropClick={onClose} onHide={onClose}>
            <Modal.Body >
                <div className="error_container">
                    <div className="error_content">
                        <MdError /> <strong>Error</strong>
                    </div>

                    <MdClose className='close' onClick={onClose} />
                </div>
                <div className='error'>
                    {message ? message : "Something went wrong!"}
                </div>


            </Modal.Body>
        </Modal>
    );
};

export default ErrorPopup;

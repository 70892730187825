import React, { useEffect, useState } from 'react';
import { Row, Col, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import FooterCard from '../components/card/FooterCard.js';
import RedemptionCard from '../components/card/RedemptionCard.js';
import Navbar from '../components/navbar/Navbar.js';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVoucher } from '../redux/voucherActions.js';
import ErrorPopup from '../components/ErrorPopup/ErrorPopup.js'; // Import ErrorPopup
import { useSearchParams } from "react-router-dom";

const loadingOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
    backdropFilter: 'blur(5px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 9999,
};

const footer_cards = [
    {
        title: "Got questions ?",
        description: "Check out our Help Center for User Guides and Frequently Asked Questions on how to use the voucher.",
        button_text: "Help Center",
        link: "/help"
    },
    {
        title: "Contact our sales team",
        description: "We are here to get you started or consult you on the solutions that best fit your business need.",
        button_text: <><span> email to</span> <span style={{ fontWeight: "bold"}}>&nbsp;sales@fincentive.co</span></>,
        link: "/"
    }
]

function HomePage() {
    const [searchParams] = useSearchParams();
    const code = searchParams.get("code") ? searchParams.get("code").toUpperCase().replace(/[^A-Z0-9-]/g, "") : "";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { voucher, loading, error } = useSelector((state) => state.voucher);
    const [showErrorPopup, setShowErrorPopup] = useState(false);



    console.log(showErrorPopup, "error value")
    useEffect(() => {
        if (voucher) {
            navigate('/create-voucher');
        }
    }, [voucher, navigate]);

    useEffect(() => {
        if (error) {
            setShowErrorPopup(true);
        }
    }, [error]);

   

    const handlePopupClose = () => {
        setShowErrorPopup(false);
    };

    // Handle the actual submission logic
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            console.log("Handling form submission");
            await dispatch(fetchVoucher(e.target.code.value, e.target.pin.value));
            console.log("Voucher fetched successfullyklmlmlml");
        } catch (error) {
            console.error("Error fetching voucher:", error);
        }
    };



    return (
        <div className="dotted-background">
            {loading && (
                <div className="loading-overlay" style={loadingOverlayStyle}>
                    <Spinner animation="border" variant="primary" />
                </div>
            )}
            <div className='landing'>
                <Navbar />
                <Row className='homepage-top' >
                    <Col sm={12} md={6} className='banner-text pt-sm-5'>


                        Welcome, <br></br>
                        Please enter the eGift voucher code & pin to redeem or check balance.

                    </Col>

                    {/* {home_cards.map(item => (
                    <Col> <HomeCard sm={12} title={item.title} description={item.description} Logo={item.logo} button_text={item.button_text} link={item.link} /></Col>
                ))} */}

                    <Col sm={12} md={6}  >
                        <RedemptionCard code={code} handleSubmit={handleSubmit} />
                    </Col>

                </Row>


                {/* Error Popup */}
                <ErrorPopup
                    show={showErrorPopup}
                    message={error}
                    onClose={handlePopupClose}
                />
            </div>





            <div className='footer'>

                <Row className='footer-cards' >
                    {footer_cards.map((item, index) => (
                        <Col sm={12} md={6} lg={6} key={index}> <FooterCard title={item.title} description={item.description} button_text={item.button_text} link={item.link} /></Col>
                    ))}
                </Row>

                <div className='footer-links'>
                    <div>Terms of Use</div>
                    <div>Privacy Policy</div>
                    <div>© Fincentive Technologies Pvt Ltd. 2024</div>
                </div>
            </div>

        </div>
    )



}

export default HomePage;

import Card from 'react-bootstrap/Card';
import { CardBody, Form } from 'react-bootstrap';
import PrimaryButton from '../button/PrimaryButton';
import './redemption-card.scss'
import React, { useState, useEffect } from 'react';
import { ReactComponent as RedeemVoucherLogo } from '../../assests/redeem_voucher.svg'
// import ReCAPTCHA from "react-google-recaptcha";

function RedemptionCard({ handleSubmit, onChange, code }) {
    const [voucherCode, setVoucherCode] = useState(code);
    const [pin, setPin] = useState('');
    const [errors, setErrors] = useState({});
    // const [recaptchaToken, setRecaptchaToken] = useState(null);
    // const [recaptchaExpired, setRecaptchaExpired] = useState(false);

    // const handleRecaptchaChange = (token) => {
       
    //     setRecaptchaToken(token);
    //     setRecaptchaExpired(false);
    //     if(token){
    //         setErrors((prevState)=> {
    //             return {
    //                 ...prevState,
    //                 captcha: ""
    //             }
    //         })
    //     }

    // };


    // Validation function
    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!voucherCode) {
            formErrors.voucherCode = "Voucher code cannot be empty.";
            isValid = false;
        }

        if(!pin){
            formErrors.pin = "Pin cannot be empty.";
            isValid = false;
        }
        else{
            if (!/^\d{4}$/.test(pin)) {
                formErrors.pin = "Voucher pin must be a four-digit number.";
                isValid = false;
            }
        }
       
       

        // if (!recaptchaToken) {
        //     formErrors.captcha = "Verify captcha"
        //     isValid = false

        // }

        // if (recaptchaExpired) {
        //     formErrors.captcha = "Re-Verify captcha"
        //     isValid = false
        // }
        setErrors(formErrors);
        return isValid;
    };

    // Handle form submission
    const onSubmit = async (e) => {
        e.preventDefault(); // Prevent default form submission
        console.log("Form submission started");

        if (validateForm()) {
            try {
                await handleSubmit(e);
            } catch (error) {
                console.error("Error during form submission:", error);
            }
        } else {
            console.log("Form validation failed");
        }
    };

    return (
        <Card className='redemption-card'>
            <Form onSubmit={onSubmit}>
                <Card.Body>
                    <RedeemVoucherLogo className="logo" />
                </Card.Body>

                <Card.Body>
                    <Form.Group className="mb-3" controlId="code">
                        <Form.Label>Enter Voucher Code</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="e.g. DHJ738 9JSH2 991HY"
                            value={voucherCode}
                            onChange={(e) =>{
                                let value = e.target.value.toUpperCase().replace(/[^A-Z0-9-]/g, "");
                                setErrors((prevState)=>{
                                    return {
                                        ...prevState,
                                        voucherCode:""
                                    }
                                })
                                setVoucherCode(value)}}
                            isInvalid={!!errors.voucherCode}
                        />
                        {errors.voucherCode && (
                            <Form.Control.Feedback type="invalid">
                                {errors.voucherCode}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Card.Body>

                <Card.Body>
                    <Form.Group className="mb-3" controlId="pin">
                        <Form.Label>Enter Voucher PIN</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="PIN"
                            value={pin}
                            onChange={(e) => {
                                let value = e.target.value.toUpperCase().replace(/[^0-9]/g, "");
                                setErrors((prevState)=>{
                                    return {
                                        ...prevState,
                                        pin:""
                                    }
                                })
                                setPin(value)
                            }}
                            isInvalid={!!errors.pin}
                        />
                        {errors.pin && (
                            <Form.Control.Feedback type="invalid">
                                {errors.pin}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Card.Body>

                {/* <CardBody className='captcha' >
                    <Form.Group className="mb-3" controlId="pin">


                        <ReCAPTCHA
                            sitekey={window.env.RECAPTCHA_KEY}
                            onChange={handleRecaptchaChange}
                            className="g-recaptcha is-invalid"
                            onExpired={() => {
                                setRecaptchaExpired(true);
                                setRecaptchaToken(null); // Clear the expired token
                            }}
                        />



                        {errors.captcha && (
                            <Form.Control.Feedback type="invalid">
                                {errors.captcha}
                            </Form.Control.Feedback>
                        )}




                    </Form.Group>
                </CardBody> */}

                <CardBody className='card-btn'>
                    <PrimaryButton>Continue</PrimaryButton>
                </CardBody>
            </Form>
        </Card>
    );
}

export default RedemptionCard;
